* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.fk6fouc {
  font-family: "Poppins", sans-serif !important;
}

/* Light Theme */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
}

[data-theme="dark"] {
  --background-color: #121212;
  --text-color: #ffffff;
}
[data-theme="dark"] .sub-title {
  color: #ffffff;
}
[data-theme="dark"] .sidebar {
  background: #161618;
  border-right: 1px solid #3e3b3b;
}
/* [data-theme="dark"] .sidemenu-wrapper
{
  background: #000;
} */
[data-theme="dark"] .active-sidemenu {
  /* background: #161C29; */
  background: #191919;
}
[data-theme="dark"] .icon-box {
  background: #1c1c1e;
}
[data-theme="dark"] .active-sidemenu .icon-box {
  background: #1c1c1e;
}
[data-theme="dark"] .battery-text {
  color: #9fb0d0;
}
[data-theme="dark"] .profile-heading,
[data-theme="dark"] .profile-value {
  color: #fff;
}
[data-theme="dark"] label {
  color: #fff !important;
}
[data-theme="dark"] .offline {
  background: #353539;
  color: #f3f6fa;
}
[data-theme="dark"] .modal-content {
  background-color: #1c1c1e !important;
}
[data-theme="dark"] .modal-title {
  color: #fff !important;
}
[data-theme="dark"] .form-control {
  background-color: #b8b8b800 !important;
  color: #fff !important;
}
[data-theme="dark"] .form-select {
  color: #dfdfdf !important;
}
[data-theme="dark"] .form-select option {
  color: #000 !important;
}
[data-theme="dark"] .read-more {
  color: #606064;
  border-bottom: 1px solid #606064;
}
[data-theme="dark"] .modal-header {
  color: #fff !important;
}
[data-theme="dark"] .fxugw4r {
  background-color: #161618 !important;
}
[data-theme="dark"] .fxugw4r button span {
  color: #fff !important;
}
[data-theme="dark"] .select__control {
  background-color: transparent !important;
}
[data-theme="dark"] .select__menu {
  background-color: #212b36 !important;
  color: #5d5d5d !important;
}
[data-theme="dark"] .box-devices-content {
  color: #fff;
}
[data-theme="dark"] .modal-backdrop {
  background-color: #35343a;
}
[data-theme="dark"] .login .form-control {
  color: #464646 !important;
}
[data-theme="dark"] .login label {
  color: #082064 !important;
}
[data-theme="dark"] .ans-right {
  color: #ededed;
}

[data-theme="dark"] .dropdown-devices {
  background: #161618 !important;
}
[data-theme="dark"] .dropdown-devices span {
  color: #fff;
}
[data-theme="dark"] .table-style td {
  border-bottom: 1px solid #3a3a3a;
}
[data-theme="dark"] .table > :not(caption) > * > * {
  background-color: #161618;
  color: #fff;
}
[data-theme="dark"] .table-header th {
  border-bottom: 1px solid #3a3a3a;
}
[data-theme="dark"] .page-link {
  background-color: #161618;
  color: #c7c8c9;
  border-color: #3a3a3a;
}
/* [data-theme="dark"] .page-link.active
{
  background: #191919;
  border-color: #dfdfdf;
} */
.fxugw4r {
  background-color: #f6f6fc !important;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  background-color: var(--background-color) !important;
  color: #000;
}

[data-theme="dark"] body {
  color: #fff;
}
[data-theme="dark"] body .login {
  color: #000;
}
.sub-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.sub-list::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #051A54;  */
  border-radius: 10px;
  background: #051a54;
}
label {
  color: #082064 !important;
  font-size: 16px;
  font-weight: 400;
}
.box-devices-content {
  color: #082064;
}
/* file */
[type="file"] {
  color: transparent !important; /* Hide the text */
  border: 1px dashed #dee2e6 !important; /* Explicitly define the border */
  border-radius: 10px !important; /* Maintain border radius */
  background: #ffffff; /* Set background color */
}

[type="file"]::-webkit-file-upload-button {
  background: transparent !important;
  border: none !important; /* Ensure no extra border from the button */
  color: #082064 !important;
  cursor: pointer !important;
  font-size: 12px !important;
  outline: none !important;
  padding: 10px 25px !important;
  text-transform: uppercase !important;
  transition: all 0.3s ease !important;
}

[type="file"]::-webkit-file-upload-button:hover {
  background: #fff !important;
  border: 2px solid #535353 !important;
  color: #000 !important;
}
[type="file"]::file-selector-button {
  background: transparent;
  border: none;
  color: #082064;
  cursor: pointer;
}

.form-control[type="file"] {
  /* border: 1px dashed !important; */
  background: #ffffff;
  border-color: #dee2e6;
  border-radius: 10px !important;
  width: 100%;
}

/* end file */
/* Handle */
.sub-list::-webkit-scrollbar-thumb {
  background: #25459e;
  border-radius: 10px;
}

/* Handle on hover scrollbar */
.sub-list::-webkit-scrollbar-thumb:hover {
  background: #25459e;
}
/* scrollbar */
/* end */
.scroll-height::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.scroll-height::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #051A54;  */
  border-radius: 10px;
  background: #eeeeee;
}

/* Handle */
.scroll-height::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover scrollbar */
.scroll-height::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}
/* scrollbar */
p {
  font-size: 16px;
}
ul {
  padding-left: 0;
}
li,
a {
  text-decoration: none;
  list-style: none;
  color: unset;
}
.small-switch .form-check-input {
  transform: scale(0.7); /* Reduce size of the switch */
}

.small-switch .form-check-label {
  font-size: 0.8rem; /* Adjust label size if needed */
}

.small-switch {
  padding: 0.2rem 0; /* Reduce padding */
}
a {
  color: #082064;
}
a:hover {
  color: #009dcf;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  /* font-weight: bold; */
  font-style: normal;
  font-weight: 600;
}
/* common */
.btn-primary {
  color: #fff;
  background-color: #082064;
  border-color: #082064;
}
.btn-outline-primary {
  color: #082064;
  border-color: #082064;
}

.piechart-wrapper {
  display: grid;
  place-items: center;
  padding: 10px 0;
}

.grid-1fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #001550;
  border-color: #001550;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #001550;
  border-color: #001550;
}
label {
  color: #262626;
}
.register-page .form-select {
  background: url(./Assets/Images/select-down-arrow.png) no-repeat !important;
  background-size: 11px 8px !important;
  background-position: right 0.75rem center !important;
  border: 1px solid #d1d1d180;
  border-radius: 10px !important;
  /* padding: 15px 0 15px 50px; */
  padding: 0.375rem 0.75rem;
  font-size: 16px;
}
.form-select {
  background: url(./Assets/Images/select-down-arrow.png) no-repeat !important;
  background-size: 11px 8px !important;
  background-position: right 0.75rem center !important;
  border: 1px solid #d1d1d180;
  border-radius: 10px !important;
  padding: 10px 0 10px 15px;
  font-size: 16px;
}
.form-select::after {
  content: "";
  border: 1px solid #000;
}
.line-select {
  width: 1px;
  height: 100%;
  background: #00b7f1;
  position: absolute;
  right: 47px;
}
.select-line {
  position: relative;
  display: flex;
}
.radio {
  position: relative;
  margin: 13px;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.table-style-dashboard table tr td {
  padding: 12.5px 15px !important;
}
.table-style-dashboard table tr th {
  padding: 12px 15px !important;
}
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 2px solid #00b7f1;
  border-radius: 50%;
}

.radio:hover input ~ .checkmark {
  background-color: #fff;
}

.radio input:checked ~ .checkmark {
  border: 2px solid #00b7f1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked ~ .checkmark:after {
  display: block;
}

.radio .checkmark:after {
  top: 1px;
  left: 1px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #00b7f1;
}
/*  */
.border-right-line {
  border-right: 1px solid #00b7f1;
}
/*  */

img {
  width: 100%;
}
.first-box {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.table-style-dashboard {
  width: 55%;
}
/* login page */
.inside-right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.inside-right-box {
  width: 55%;
}
.login-left {
  background: url(./Assets/Images/busy-open-plan-office.png) no-repeat;
  background-size: 100% 100%;
}
.inside-box {
  color: #fff;
}
.login-left-inside {
  width: 55%;
  margin: 0 auto;
  padding: 40px 0 30px;
  height: 100vh;
}
.inside-box h1 {
  font-size: 75px;
}
.login-left p {
  font-size: 14px;
}
.icon-position {
  position: absolute;
  bottom: 18px;
  left: 15px;
}
.icon-eye {
  position: absolute;
  bottom: 16px;
  right: 15px;
}
.form-details .form-control {
  padding: 15px 0 15px 50px;
}
.footer-copyrightss {
  display: none;
}
.footer-copyright {
  display: block;
}
.register-page .form-details .mb-4 {
  margin-bottom: 0.5rem !important;
}
/* end login page */
.login-logo-img {
  width: 225px;
}
.login-box {
  padding: 100px 40px;
  background: #fff;
  border-radius: 45px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.login-box .form-control,
.login-box .form-select {
  padding: 0.575rem 0.95rem;
  border-radius: 5px;
}

.form-control {
  border: 1px solid #d1d1d180;
  border-radius: 10px !important;
  padding: 10px 0 10px 15px;
  font-size: 16px;
}
.modal-dialog .form-control {
  border: 1px solid #d1d1d180;
  padding: 0.6rem 0.75rem;
}
.modal-dialog .form-select {
  padding: 0.6rem 0.75rem;
}
.border-radiuss {
  border-radius: 5px !important;
}
.login-box label {
  margin-left: 15px;
}
.services-admin-list-input {
  border: 1px solid #d1d1d180;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}
.services-admin-list-input ul {
  height: 160px;
  overflow-y: scroll;
  margin-bottom: 0;
}
.services-admin-list-input ul li {
  padding: 13px 12px;
  border-bottom: 1px solid #d9d9d9;
  color: #082064;
}
.login-box .radio label {
  margin-left: 25px;
}
.icon-box {
  background: #1d215b;
  width: 55px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.btn {
  border-radius: 10px;
  padding: 8px 10px;
  font-size: 15px;
  border: 1px solid;
  font-weight: 400;
}
.login .btn {
  padding: 11px 10px;
  border: 2px solid;
}
.btn-min-width {
  min-width: 160px;
}
.login-box .btn {
  font-weight: 500;
}
/* .profile-space {
  margin-top: 45px;
  margin-left: 45px;
} */
.title-profile {
  color: #082064;
  font-weight: 600;
}
.profile-section form {
  width: 50%;
}
.profile-section form .form-select {
  padding-left: 15px;
}
/*  */

.avatar-edit {
  position: absolute;
  right: 11px;
  z-index: 1;
  bottom: 20px;
}
.avatar-edit input {
  display: none;
}
.avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #5bced7;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #fff;
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
/* start */
/*  */
.accordion-item {
  background-color: #082064;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.cursor-pointer {
  cursor: pointer;
}

/*  */
/* mobile view */
.nav-mobile {
  position: relative;
  display: none;
}

.nav-bar {
  position: fixed;
  background: #082064;
  top: 0;
  right: 100%;
  height: 100vh;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: position 2.9s;
  -webkit-transition: position 2.9s;
  -moz-transition: position 2.9s;
  -ms-transition: position 2.9s;
  -o-transition: position 2.9s;
  z-index: 9999;
  overflow-y: auto;
}

.toggle {
  left: 0;
  box-shadow: 1px 0 15px 2px rgba(0, 0, 0, 0.4);
  padding-top: 80px;
}

.toggle-menu {
  /* background-color: rgba(0, 0, 0, 0.2); */
  position: fixed;
  top: 24px;
  left: 20px;
  width: 40px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2px 2px 2px 3px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 9999;
}

.line {
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-color: #000;
  transition: transform 0.2s ease-out;
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -ms-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
}

.toggle .line1 {
  background-color: #000000;
  transform: scale(0.9) rotateZ(-45deg) translate(-6px, 4px);
}

.toggle .line2 {
  display: none;
}

.toggle .line3 {
  background-color: #000000;
  transform: scale(0.9) rotateZ(45deg) translate(-6px, -4px);
}

.toggle .toggle-menu {
  background-color: white;
}

.nav-list {
  list-style: none;
}

.nav-list-item {
  text-align: left;
  padding: 10px 0px 10px 4px;
}

.header__nav .nav-link {
  color: #00b8f1;
  font-size: 19px;
  text-decoration: none;
  position: relative;
  padding-bottom: 4px;
}

.header__nav .nav-link::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.4s ease-in-out;
  transform-origin: left;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
  -ms-transition: transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
}

.header__nav .nav-link:hover::before {
  transform: scaleX(1);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* end mobile view */
.bx-menu:before {
  content: none !important;
}
.btn-group sup {
  background: #e70404;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  left: -11px;
  top: -9px;
  font-size: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*  */
.bg-space-radius {
  padding: 30px 25px;
  border-radius: 20px;
  height: calc(100% - 60px);
}
/*  */
.sidebar {
  height: 100vh;
  width: 275px;
  background: #082064;
  transition: all 0.5s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-close {
  width: 100px;
}
.sidebar.close {
  width: 85px;
}
.sidebar.close .accordion-item .accordion-body {
  display: none;
}
.sidebar .logo-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.sidebar .logo-details i {
  font-size: 30px;
  color: #00b8f1;
  height: 50px;
  min-width: 0px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .logo-details .d-logo {
  display: none;
}
.sidebar .logo-details .full-logo {
  display: block;
}
.sidebar .logo-details .full-logo img {
  width: 100px;
}
.sidebar.close .logo-details .full-logo {
  display: none;
}
.sidebar.close .logo-details .d-logo {
  display: block;
}

.sidebar .nav-links {
  /* height: 65%; */
  padding: 0 0 15px 0;
  overflow: auto;
  margin-top: 20px;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
  /* margin-bottom: 20px; */
  padding-left: 15px;
  border-bottom: 0px solid #00b7f15c;
  padding: 5px 0;
}

.sidebar .nav-links li:hover a span {
  color: #36c3ce;
}
.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .icon-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li ul li {
  margin-bottom: 0;
  padding-left: 55px !important;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 5px 20px;
  border-radius: 0px;
  color: #fff;
  background: #082064;
}
.sidebar .nav-links li a.active {
  background: #0a2574;
}
.sidebar .nav-links li a.active .icon-box {
  background: #36c3ce;
}
.sidebar .nav-links li a .link_name {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar .nav-links li a .link_name.active {
  font-weight: 700;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
  display: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #31c7f5;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 15px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 3px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}
.home-section {
  position: relative;
  left: 275px;
  width: calc(100% - 275px);
  transition: all 0.5s ease;
  /* background: #fff; */
}
.sidebar.close ~ .home-section {
  left: 85px;
  width: calc(100% - 85px);
}
.bottom-nav {
  position: absolute;
  bottom: 0;
}
.home-section .home-content {
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%;
  padding: 0.7rem 0;
  position: fixed;
  top: 0;
  z-index: 99;
  width: inherit;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: none;
}
.monitoring-main .home-content {
  display: block;
}
.bx-menu {
  cursor: pointer;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}
.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}
.sidebar .nav-links li a img {
  width: 20px;
}
.sidebar .nav-links li a img.arrow-icon {
  width: 7px;
}
@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}
.sidebar .nav-links li ul li.active a {
  color: #36c3ce !important;
}
/* .sidebar .nav-links li ul li.active::before
  {
    content: '';
    width: 0px;
    height: 0px;
    border-top: 17px solid transparent;
    border-bottom: 18px solid transparent;
    border-right: 16px solid #00b7f1;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(180deg); 
  } */
/* end */
.profile-img {
  width: 55px;
  height: 55px;
}
.profile-img img {
  border-radius: 45px;
}
.profile-img-content {
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;
}
.profile-img-content h6 {
  color: #6d7aa7;
  font-weight: 400;
}
.main-title {
  color: #5578ba;
  font-weight: 400;
  font-size: 20px;
}
.search .form-control {
  border-radius: 4px !important;
  padding: 5px 5px 5px 35px;
  height: 38px;
  border-color: #8893b7;
}
.add-delete-icon {
  border-radius: 4px !important;
  height: 38px;
  border: 1px solid #8893b7;
  margin-left: 15px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-delete-icon a {
  padding: 5px 10px 5px 10px;
}

.monitorig-icon-style {
  width: 75px;
  height: 75px;
  background: #36c3ce29;
  padding: 21px;
  border-radius: 100%;
  margin: 0 auto;
  line-height: 36px;
}
.monitoring-popup {
  margin-top: 15px;
}
.monitorings-popup {
  margin-top: 15px;
}
.monitoring-popup li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #d9d9d9;
  color: #082064;
}
.monitoring-search .search .form-control {
  border-radius: 20px !important;
  border-color: #5bced7;
}
.monitoring-search .search i {
  color: #5bced7;
}
.monitorings-popup li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #d9d9d9;
  color: #082064;
}
.monitorings-search .search .form-control {
  border-radius: 20px !important;
  border-color: #5bced7;
}
.monitorings-search .search i {
  color: #5bced7;
}
.notification-boxes {
  border: 1px solid #d9d9d9;
  padding: 15px;
  border-radius: 15px;
  margin-top: 20px;
  height: 130px !important;
  overflow-y: auto;
}
.notification-boxes li a {
  padding: 5px;
  width: 100%;
  display: block;
}
.notification-boxes li a.active {
  background: #00b7f15e;
}
.notification-title {
  color: #8f9abb;
  font-weight: 400;
  font-size: 16px;
}
/* .ul-monitoring
{
  padding-left: 30px;
}
.ul-monitoring li 
{
  list-style: disc !important;
}
.ul-monitoring li::marker {
  color: #5BCED7; 
} */
.ul-monitoring {
  list-style-type: none; /* Remove default bullets */
}
.ul-monitoring li {
  position: relative;
  padding-left: 24px; /* Adjust padding to make space for the custom bullet */
  color: #6d7aa7;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ul-monitoring li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 13px; /* Custom bullet width */
  height: 13px; /* Custom bullet height */
  background-color: #5bced7; /* Bullet color */
  border-radius: 50%; /* Make it a circle */
  transform: translateY(-50%); /* Center vertically */
}
.scroll-height {
  height: 150px;
  overflow-y: auto;
}
.sub-title {
  color: #5578ba;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.search {
  position: relative;
}
.search i {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.profile-img-content p {
  font-size: 13px;
}
.inner-bg {
  background: #f6f6fc;
}
.btn-outline-secondary:hover {
  color: #082064;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: none;
}
.ms-n5 {
  margin-left: -40px;
}
.search-style input,
.search-style input span button i {
  color: #00b7f1;
  border: 1px solid #00b7f1 !important;
}
.btn-outline-secondary {
  color: #c4c4c4;
  border-color: #e3e3e3;
}

.sidebar-accordion .accordion-button:not(.collapsed) {
  color: #00b7f1;
  background-color: transparent;
  box-shadow: none;
}
.sidebar-accordion .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.sidebar-accordion .accordion-item {
  background-color: #082064;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.accordion-button::after {
  content: none; /* Remove the pseudo-element completely */
}
/* .sub-list
  {
    padding-left: 15px;
  } */
.sidebar .accordion-body {
  padding: 0;
}
.sub-list {
  max-height: 140px;
  overflow-y: scroll;
}
.sub-list li {
  border-bottom: 0 !important;
  padding: 3px 0 !important;
}
.sub-list li a {
  justify-content: space-between !important;
}
.edit-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 15px;
  border: 1px solid #00b7f1 !important;
  border-radius: 50px;
  background: #00b7f1;
}
.edit-round i {
  color: #fff;
  font-size: 15px;
}
.sidebar .nav-links li:last-child {
  border: 0;
}
.sidebar.close .search-style {
  display: none;
}
.sidebar.close .copy-right {
  display: none;
}
.copy-right {
  padding: 10px 15px;
  font-size: 12px;
  text-align: center;
  color: #fff;
}
/* end sidebar */
/* main */
.full-height {
  height: 100vh;
  height: calc(100vh - 80px);
}
.full-height .bg-space-radius {
  height: 100%;
}
.project-task {
  padding: 2rem;
  margin-top: 0px;
}
.monitoring-main .project-task {
  margin-top: 75px;
}
/* .bg-blue-th
  {
      background: #00b7f1;
      color: #fff;
     
  } */
/* .bg-blue-th th{
    font-weight: 400;
  } */
.form-check .form-check-input {
  float: none;
}
.table-responsive {
  border-radius: 8px;
}
.table-header th {
  border: none; /* Remove borders */
  color: #5578ba;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #dee2e6;
}
.table-style td {
  color: #6d7aa7;
  font-size: 15px;
  font-weight: 400;
  border-bottom: 1px solid #dee2e6;
  padding: 0.5rem 0.5rem;
}
.form-check-input:checked {
  background-color: #00b7f1;
  border-color: #00b7f1;
}
.modal-footer {
  border-top: 0px solid #dee2e6;
  padding: 0 0.75rem 0.75rem 0.75rem;
}
.modal-content {
  border-radius: 1.3rem !important;
  border: 0px solid rgba(0, 0, 0, 0.2) !important;
  padding: 30px 25px !important;
}
.modal-header {
  background: transparent;
  border-top-left-radius: calc(1.3rem - 1px);
  border-top-right-radius: calc(1.3rem - 1px);
  border-bottom: 1px solid #dee2e6;
  color: #082064;
  font-weight: 500;
  padding: 1rem 2rem;
  padding: 0 0 10px 0 !important;
  margin-bottom: 15px !important;
}
.modal-body label {
  color: #082064;
}
.modal-body {
  padding: 0 !important;
}
.modal-title {
  font-weight: 400;
}
/* .right-sidebar
{
    width: 250px;
    margin-top: 75px;
    padding: 15px;
    height: calc(100vh - 75px);
}
.right-sidebar ul li 
{
    padding: 15px 0 0;
    color: #00b7f1;
    font-size: 17px;
    font-weight: 500;
} */
.profile-img-main {
  width: 280px;
  height: 280px;
  margin: 0 auto;
  position: relative;
  border: 10px solid #f0efef;
  border-radius: 100%;
}
.profile-img-main img {
  border-radius: 50%;
}
.profile-section p {
  /* font-weight: 400;
  color: #5578ba; */
  /* width: 130px; */
}
.profile-section p.profile-answer {
  color: #082064;
  font-weight: 400;
  width: auto;
}
.profile-box {
  border: 1px solid #00b7f1;
  width: 40%;
  margin: 0 auto;
  padding: 25px 35px;
  border-radius: 30px;
  text-align: left;
}
.profile-box label {
  font-weight: 600;
  margin-bottom: 10px;
}
.profile-box p {
  margin-bottom: 20px;
}
/* img upload */
.img-wrapper-upload {
  position: absolute;
  bottom: -18px;
  z-index: 9;
  /* z-index: 999; */
  right: 8px;
  width: 19%;
}
.img-upload-btn {
  padding: 5px;
  background: #00b7f1;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* img upload */
.report-style {
  border: 1px solid #00b7f1;
  text-align: left;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.heading-report-sty {
  padding: 15px 20px;
  background: #00b7f1;
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.report-box {
  padding: 15px 30px;
  position: relative;
}
.report-box label {
  font-weight: 600;
  margin-bottom: 5px;
}
.report-box label p {
  margin-bottom: 20px;
}
.width-report {
  width: 140px;
}
.bg-blue-box {
  width: 100%;
  background: #00b7f1;
  height: 35px;
  border-radius: 5px;
}
.online {
  background: #e8ffda;
  color: #8ad05f;
  padding: 3px 15px;
  border-radius: 20px;
}
.offline {
  background: #f3f6fa;
  color: #6d7aa7;
  padding: 3px 15px;
  border-radius: 20px;
}
.medium {
  background: #e5f5fa;
  color: #6d7aa7;
  padding: 3px 15px;
  border-radius: 20px;
}
.closed {
  background: #ffece4;
  color: #be7c7c;
  padding: 3px 15px;
  border-radius: 20px;
}
.active-table {
  background: #e8ffda;
  color: #6bb43e;
  padding: 3px 15px;
  border-radius: 20px;
}
.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-link.active {
  background: #5bced7 !important;
  color: #fff;
  border-color: #5bced7 !important;
}
.page-link {
  margin: 5px;
  border: 1px solid #d5d4df;
}
.report-box input,
.select-line {
  width: 40%;
}
.report-save {
  position: absolute;
  right: 20px;
}
/*  */
/* dashboard */
.graph-box-line {
  border: 1px solid #00b7f1;
  border-radius: 20px;
  padding: 30px;
}
.small-graph-box {
  border: 0px solid #00b7f1;
  border-radius: 20px;
  /* padding: 15px; */
  /* height: 100%; */
}
/* svg */

.bg {
  fill: none;
  stroke-width: 15px;
  /* stroke: #e8e8e8; */
  /* stroke: #b1b1b1; */
  stroke: #f2f5fa;
}
.per-graph {
  position: absolute;
  bottom: 25%;
  margin: 0;
  font-size: 25px;
  transform: rotate(100deg);
  right: 28px;
  background: transparent;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #082064;
  font-weight: 600;
  flex-direction: column;
}
.per-graph-2 {
  position: absolute;
  bottom: 25%;
  margin: 0;
  font-size: 25px;
  transform: rotate(100deg);
  right: 28px;
  background: #cc0001;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #082064;
}
.svg-style {
  width: 140px;
  position: relative;
  margin: 5px auto;
  transform: rotate(260deg);
}
.svg-style [class^="meter-"] {
  fill: none;
  stroke-width: 15px;
  stroke-linecap: round;
}

.meter-1 {
  stroke-dasharray: 479;
  stroke-dashoffset: 100;
  animation: progress-1 1s ease-out;
}

.meter-2 {
  stroke-dasharray: 283;
  stroke-dashoffset: 102;
  animation: progress-2 1s ease-out;
}

@keyframes progress-1 {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 100;
  }
}

@keyframes progress-2 {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 160;
  }
}

@keyframes progress-3 {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 250;
  }
}

/* end svg */
/* battery */
.battery {
  height: 127px;
  width: 65px;
  position: relative;
  border-radius: 10px;
  display: flex;
  background: #b2b2b2;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px; */
}

.batterybx {
  height: 90%;
  width: 100%;
  background-color: #00adf5;
  bottom: 0;
  left: 0px;
  position: absolute;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border-radius: 4px;
    margin: auto 3px; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px; */
}

/* battery */
/* gauge */
.box {
  position: relative;
}
.gauge-wrap {
  position: relative;
  margin: 0 0 20px;
  width: 200px;
  height: 100px;
}

.gauge-core {
  position: relative;
  overflow: hidden;
  height: inherit;
  width: inherit;
}

.gauge-wrap-before {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: -7px; /* 1px less to cover clipping */
  left: 0px;
  clip: rect(8px, 16px, 16px, 0px);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  z-index: 5;
}

.gauge-wrap-after {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: -7px; /* 1px less to cover clipping */
  right: 0px;
  clip: rect(8px, 16px, 16px, 0px);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  z-index: 5;
}

.gauge-bg {
  position: absolute;
  width: 200px;
  height: 200px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  z-index: 0;
}

.gauge-active-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.gauge-active {
  top: 100px;
  position: absolute;
  width: 200px;
  height: 100px;
  overflow: visible;
  -webkit-border-radius: 0px 0px 200px 200px;
  -moz-border-radius: 0px 0px 200px 200px;
  border-radius: 0px 0px 200px 200px;
  z-index: 1;
}

.gauge-active-before {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: inherit;
  top: -7px; /* 1px less to cover clipping */
  left: 0px;
  clip: rect(0px, 16px, 8px, 0px);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  z-index: 5;
}

.gauge-cap {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 168px;
  height: 168px;
  -webkit-border-radius: 84px;
  -moz-border-radius: 84px;
  border-radius: 84px;
  z-index: 5;
}
.gauge-inside {
  position: absolute;
  bottom: 0;
  right: 29%;
  z-index: 9;
  text-align: center;
}
.gauge-inside p {
  font-size: 18px;
}
.gauge-inside p:first-child {
  font-size: 25px;
}
/* gauge */
/* device live status */
.device-live {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  margin: 15px auto;
}
.bg-red {
  background: #c80000;
}
.font-red {
  color: #c80000;
}
.font-blue {
  color: #00b7f1;
}
.bg-blue {
  background: #00b7f1;
}
/*  */
/* progressbar */
.barcontainer {
  position: relative;
  width: 4%;
  height: 250px;
  background: #f3f6fa;
  bottom: 30px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.small-graph-box p {
  color: #00b7f1;
}
.bar {
  background-color: #32a9c7;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
  box-sizing: border-box;
  -webkit-animation: grow 1.5s ease-out forwards;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
/* end progressbar */
/* wan status */
.wan-gauge {
  /* width: 50%; */
  margin-right: 10px;
}
.wan-gauge .gauge-inside {
  right: 33%;
}
.wan-gauge.wan-gauge-2 {
  margin-right: 0;
}
.wan-gauge.wan-gauge-2 .gauge-inside {
  right: 24%;
}
/* end wan */
/* range */
.range-item {
  position: relative;
}
.range-item:not(:last-child) {
  margin-bottom: 24px;
}
.range-item .range-input {
  margin-top: 6px;
}
.range-item .range-input input {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.range-item ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: calc(100% + 34.4px);
  margin: 0 -17px;
}
.range-item ul .list-inline-item:not(:last-child) {
  margin-right: 0;
}
.range-item ul .list-inline-item span {
  position: relative;
  min-width: 58px;
  text-align: center;
  display: block;
  font-size: 14px;
  padding-top: 4px;
  word-break: break-word;
  color: #00b7f1;
}
.range-item .range-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 3px;
  width: calc(100% - 14px);
  background-color: #81e4f3;
  z-index: 0;
  margin: 0 7px;
  border-radius: 50px;
  overflow: hidden;
}
.range-item .range-input.disable-range + ul .list-inline-item span::before {
  background-color: #00b7f1;
}
.range-item .range-input.disable-range .range-line .active-line {
  background-color: #00b7f1;
}
.range-item
  .range-input.disable-range
  + ul
  .list-inline-item.active
  span::before {
  background-color: #00b7f1;
}
.range-item .range-input .dot-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 24px);
  left: 0;
  z-index: 1;
}
/* range edit scroll */
.range-item .range-input .active-dot {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  cursor: pointer;
  background-color: #00b7f1;
  height: 28px;
  width: 25px;
  border: 4px solid #00b7f1;
  z-index: 11;
  border-radius: 100px;
  height: 25px;
  /* border-bottom-left-radius: 85px;
    border-bottom-right-radius: 85px; */
}
/* .range-item .range-input .active-dot::after
  {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #00b7f1;
    position: absolute;
    bottom: -13px;
    left: -4px;
  } */
/*end range edit scroll */
.range-item .range-input .range-line .active-line {
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #00b7f1;
  transition: all 0.1s ease;
}
.range-item ul .list-inline-item span::before {
  content: "";
  display: block;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00b7f1;
}
.range-item ul .list-inline-item.active span::before {
  background-color: #00b7f1;
}
.range-item .range-input input:disabled ~ .dot-line .active-dot {
  background-color: #00b7f1;
  border: 4px solid #00b7f1;
}
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -8px; /* Centers thumb on the track */
  background-color: #0000;
  height: 15px;
  width: 15px;
  border: 4px solid #0000;
  z-index: 11;
  box-shadow: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -8px; /* Centers thumb on the track */
  background-color: #0000;
  height: 24px;
  width: 24px;
  border: 4px solid #0000;
  box-shadow: none;
  z-index: 11;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: #f0f0f000;
}
input[type="range"]::-moz-range-progress {
  background-color: #f0f0f000;
}
input[type="range"]::-ms-track {
  background-color: #f0f0f000;
}
input[type="range"]::-moz-range-track {
  background-color: #f0f0f000;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #f0f0f000;
}
input[type="range"]::-ms-fill-upper {
  background-color: #f0f0f000;
}
input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: none !important;
  background-color: #f0f0f000;
}

input[type="range"]:focus::-moz-range-thumb {
  box-shadow: none !important;
  background-color: #f0f0f000;
}

input[type="range"]:focus::-ms-thumb {
  background-color: #f0f0f000;
  box-shadow: none !important;
}
input[type="range"]:disabled::-webkit-slider-thumb {
  background-color: #0000;
  border: 4px solid #0000;
}
input[type="range"]:disabled::-moz-range-thumb {
  background-color: #0000;
  border: 4px solid #0000;
}

/* end range */
/*  */
.range-box-normal {
  height: 25px;
}
.range-width-1 {
  width: 20%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.range-width-2 {
  width: 25%;
}
.range-width-3 {
  width: 25%;
}
.range-width-4 {
  width: 30%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.bg-blue-1 {
  background: #04cae7;
}
.bg-blue-2 {
  background: #04cae7b2;
}
.bg-blue-3 {
  background: #04cae799;
}
.bg-blue-4 {
  background: #04cae780;
}
/*  */
/* end dashboard */
/* switch code */
:root {
  --hue: 223;
  --hue-success: 103;
  --bg: hsl(var(--hue), 10%, 80%);
  --fg: hsl(var(--hue), 10%, 10%);
  --primary: hsl(var(--hue), 90%, 50%);
  --primary-t: hsla(var(--hue), 90%, 50%, 0);
  --trans-dur: 0.3s;
  --trans-timing: cubic-bezier(0.65, 0, 0.35, 1);
}
.setting {
  /* box-shadow:
		0 0.125em 0 hsl(var(--hue),10%,100%) inset,
		0 -0.125em 0 hsl(var(--hue),10%,75%) inset; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
}
/* .setting:only-child {
	border-radius: 0.25em;
}
.setting:first-child {
	border-radius: 0.25em 0.25em 0 0;
}
.setting:last-child {
	border-radius: 0 0 0.25em 0.25em;
} */
.setting__label {
  margin-right: 1em;
}
.setting,
.switch__input {
  -webkit-tap-highlight-color: transparent;
}
.switch,
.switch:before,
.switch:after {
  display: block;
}
.switch {
  background-image: linear-gradient(
    hsl(var(--hue), 10%, 60%),
    hsl(var(--hue), 10%, 95%)
  );
  box-shadow: 0 0 0.125em 0.125em hsl(var(--hue), 10%, 90%) inset;
  border-radius: 1em;
  flex-shrink: 0;
  position: relative;
  width: 5em;
  height: 2em;
}
.switch:before,
.switch:after {
  border-radius: 0.75em;
  content: "";
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  width: 4.5em;
  height: 1.5em;
}
.switch:before {
  background-color: hsl(var(--hue), 10%, 60%);
}
.switch::after {
  box-shadow: 0 0 0.5em hsl(var(--hue), 10%, 20%) inset;
  z-index: 1;
}
.switch__fill {
  border-radius: 0.75em;
  overflow: hidden;
  position: absolute;
  top: 0.25em;
  right: 0;
  left: 0.25em;
  width: 4.5em;
  height: 1.5em;
  z-index: 1;
}
.switch__input {
  border-radius: 1em;
  box-shadow: 0 0 0 0.125em var(--primary-t);
  cursor: pointer;
  outline: transparent;
  position: relative;
  width: 100%;
  height: 100%;
  transition: box-shadow calc(var(--trans-dur) / 2) var(--trans-timing);
  z-index: 2;
  -webkit-appearance: none;
  appearance: none;
}
.switch__input:focus-visible {
  box-shadow: 0 0 0 0.125em var(--primary);
}
.switch__input:before,
.switch__input:after {
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  transition: transform var(--trans-dur) var(--trans-timing);
}
.switch__input:before {
  background-image: linear-gradient(
    hsl(0, 0%, 100%),
    hsl(var(--hue), 10%, 60%)
  );
  box-shadow: 0 0 0.125em 0.0625em hsl(var(--hue), 10%, 40%),
    0 0.25em 0.25em hsla(var(--hue), 10%, 10%, 0.4);
  top: 0.125em;
  left: 0.125em;
  width: 1.75em;
  height: 1.75em;
}
.switch__input:after {
  background-image: linear-gradient(
    hsl(var(--hue), 10%, 90%),
    hsl(var(--hue), 10%, 80%)
  );
  top: 0.25em;
  left: 0.25em;
  width: 1.5em;
  height: 1.5em;
}
.switch__text {
  background-color: #00b7f1;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  opacity: 1;
  padding: 0 0.75em;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.switch__text + .switch__text {
  background-color: #00b7f1;
  color: #fff;
  text-align: right;
  transform: translateX(0);
}
.switch__input:checked:before,
.switch__input:checked:after {
  transform: translateX(3em);
}
.switch__input:checked + .switch__fill .switch__text {
  transform: translateX(0);
}
.switch__input:checked + .switch__fill .switch__text + .switch__text {
  transform: translateX(4em);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 20%);
    --fg: hsl(var(--hue), 10%, 90%);
    --primary: hsl(var(--hue), 90%, 70%);
    --primary-t: hsla(var(--hue), 90%, 70%, 0);
  }

  .setting {
    box-shadow: 0 0.125em 0 hsl(var(--hue), 10%, 40%) inset,
      0 -0.125em 0 hsl(var(--hue), 10%, 20%) inset;
  }
  .switch {
    background-image: linear-gradient(
      hsl(var(--hue), 10%, 15%),
      hsl(var(--hue), 10%, 35%)
    );
    box-shadow: 0 0 0.125em 0.125em hsl(var(--hue), 10%, 30%) inset;
  }
  .switch:before {
    background-color: hsl(var(--hue), 10%, 40%);
  }
  .switch:after {
    box-shadow: 0 0 0.5em hsl(0, 0%, 0%) inset;
  }
  .switch__input:before {
    background-image: linear-gradient(
      hsl(var(--hue), 10%, 50%),
      hsl(var(--hue), 10%, 20%)
    );
    box-shadow: 0 0 0.125em 0.0625em hsl(var(--hue), 10%, 10%),
      0 0.25em 0.25em hsla(var(--hue), 10%, 10%, 0.4);
  }
  .switch__input:after {
    background-image: linear-gradient(
      hsl(var(--hue), 10%, 40%),
      hsl(var(--hue), 10%, 30%)
    );
  }
  .switch__text {
    text-shadow: 0 0.0625rem 0 hsl(var(--hue-success), 90%, 45%);
  }
  .switch__text + .switch__text {
    color: hsl(var(--hue), 10%, 10%);
    text-shadow: 0 0.0625rem 0 hsl(var(--hue), 10%, 55%);
  }
}

/* end switch code */
.small-box {
  border: 1px solid #00b7f1;
  border-radius: 25px;
  width: 28%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.heading-box {
  background: #00b7f1;
  padding: 15px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}
.list-smallbox {
  padding: 15px;
}
.main-small-box {
  /* display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    position: relative; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  max-width: 1200px;
  margin: 0px auto;
  gap: 100px;
  position: relative;
}

/* .center {
    height: 0px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    bottom: 46%;
    left: 40%;
} */
.center {
  height: fit-content;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  bottom: 35%;
  left: 40%;
}
/* .center::before
{
    content: '';
    width: 96%;
    height: 145px;
    border-top: 2px dashed #00b7f1;
    border-right: 2px dashed #00b7f1;
    position: absolute;
    right: 152px;
    bottom: 146px;
}
.center::after
{
    content: '';
    width: 59%;
    height: 161px;
    border-top: 2px dashed #00b7f1;
    border-left: 2px dashed #00b7f1;
    position: absolute;
    left: -23px;
    bottom: -102px;
    z-index: 99;
    border-right: 2px dashed #00b7f1;
} */
.line-monitoring {
  position: relative;
}
.line-monitoring::after {
  content: "";
  border-top: 2px dashed #00b7f1;
  height: 45px;
  position: absolute;
  right: -119px;
  bottom: 31%;
  width: 34%;
}
.notification-line-box {
  position: relative;
}
.notification-line-box::before {
  content: "";
  height: 163px;
  position: absolute;
  left: -226px;
  bottom: 48%;
  width: 62%;
  border-bottom: 2px dashed #00b7f1;
}
.object-line-ups {
  position: relative;
}
.object-line-ups::before {
  content: "";
  border-left: 2px dashed #00b7f1;
  height: 163px;
  position: absolute;
  left: -36px;
  bottom: -21%;
  width: 9%;
  border-top: 2px dashed #00b7f1;
}
.object-line-ups::after {
  content: "";
  height: 163px;
  position: absolute;
  left: -50%;
  bottom: -22%;
  width: 38%;
  border-bottom: 2px dashed #00b7f1;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 12px solid #00b7f1;
  position: absolute;
  top: 48%;
  left: -16px;
}
/* .line-box-1
{
    position: absolute;
    right: -9px;
    top: 23%;
}
.line-box-1 img 
{
    width: 33%;
}
.line-box-2
{
    position: absolute;
    right: -15%;
    top: 34%;
}
.line-box-2 img 
{
    width: 25%;
}
.line-box-3
{
    position: absolute;
    right: 13%;
    bottom: 19.8%;
}
.line-box-3 img 
{
    width: 31%;
}
.line-box-4
{
    position: absolute;
    right: 26px;
    bottom: 19%;
    width: fit-content;
    text-align: center;
}
.line-box-4 img 
{
    width: 26%;
} */
.center img {
  width: 44%;
  z-index: 999;
}
.list-smallbox ul {
  border: 1px solid #b7b7b7;
  border-radius: 20px;
}
.list-smallbox ul li {
  padding: 10px 15px;
  border-bottom: 1px solid #b7b7b7;
}
.list-smallbox ul li:last-child {
  border-bottom: 0;
}
.space-main {
  padding: 20px 0;
}
.bar-subtitle p {
  margin-left: -20px;
  font-size: 11px;
  font-weight: 700;
}
.nav-list-item.accordion-item .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
}
.nav-list-item.accordion-item .sub-list li {
  padding: 10px 0 !important;
  color: #008ab6;
}
.header__nav .nav-link.active {
  font-weight: 700;
}
.nav-list-item.accordion-item .sub-list li.active {
  font-weight: 700;
}

/* dropdown */
.dropdown-devices {
  position: relative;
  display: flex;
  border: 2px solid #082064;
  border-radius: 25px;
  cursor: pointer;
  background: #fff !important;
  width: 260px;
  justify-content: space-between;
  padding: 10px 15px;
}
.dropdown-devices span {
  white-space: nowrap;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-devices .dropdown-toggle::after {
  content: none;
}
.dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
}

#device-name {
  color: #082064;
  font-weight: bold;
  margin-right: 65px;
  margin-left: 10px;
}

.dropdown-icon {
  background-color: transparent;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 250px;
}
textarea {
  resize: vertical;
  border: 1px solid #d1d1d180;
  border-radius: 10px !important;
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
/*  */
.devices-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.download-style {
  padding: 10px 16px;
  border: 1px solid #36c3ce;
  background: #fff;
  border-radius: 10px;
}
.edit-style {
  width: 40px;
  height: 40px;
  padding: 13px;
  background: #5bced7;
  border-radius: 100%;
  display: flex;
  align-items: center;
}
.report-design-style {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.section-left {
  border-right: 1px solid #d9d9d9;
}
.ques-left {
  color: #9fb0d0;
  font-size: 15px;
  font-weight: 400;
  min-width: 180px;
  margin-bottom: 0;
}
.ans-right {
  color: #082064;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  width: 60%;
}
.section-left .d-flex,
.section-right .d-flex {
  margin-bottom: 13px;
}
/* progress */
.progress {
  border-radius: 2.25rem !important;
}
.progress.new-progress {
  width: 50% !important;
}
.progress-bar {
  background-color: #36c3ce !important;
}
/* end progress */
.upload-container {
  width: 100%;
  text-align: center;
}

.upload-container h5 {
  text-align: left;
  color: #4e5b82;
  margin-bottom: 10px;
}

.file-upload {
  border: 2px dashed #dcdfe6;
  border-radius: 8px;
  padding: 30px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-upload:hover {
  background-color: #f9f9f9;
}

.file-upload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-upload span {
  color: #b1b7c1;
}

.file-upload a {
  color: #30c3d9;
  text-decoration: none;
}

.uploaded-file {
  text-align: left;
  margin-top: 10px;
}

.uploaded-file a {
  color: #30c3d9;
  text-decoration: none;
}
/*  */
.email-container {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #4e5b82;
}

.email-address {
  margin-right: 8px;
}

.email-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #30c3d9;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
}
/*  */
.add-devices {
  padding: 2px 10px;
  border: 1px solid #5bced7;
  border-radius: 20px;
  color: #5bced7;
}

.add-devices:hover {
  color: #1c4b82; /* Complementary deep blue */
  border-color: #1c4b82; /* Border color matching the deep blue */
  box-shadow: 0 0 5px #5bced7; /* Soft cyan glow to retain the original theme */
  transition: color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.active-device {
  border: 1px solid #5bced7;
  color: #082064;
  padding: 2px 10px;
  border-radius: 20px;
  margin-right: 10px;
}
.deactive-devices {
  border: 1px solid #e6e6e6;
  color: #d9d9d9;
  padding: 2px 10px;
  border-radius: 20px;
  margin-right: 10px;
}
/*  */
.customer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 0px solid white;
  margin-left: -10px;
  box-shadow: 0 0 0 2px #fff;
}

.customer-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customer-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #30c3d9;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  margin-left: -10px;
  box-shadow: 0 0 0 2px #fff;
}
.devices-performance {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}
.devices-performance p {
  color: #6d7aa7;
}
.box-devices {
  width: 12px;
  height: 9px;
}
.green-box,
.green-progress-bar {
  background: #bbfb94;
}
.green-box,
.green-progress-bars {
  background: #bbfb94;
}
.gray-box,
.gray-progress-bar {
  background: #6d7aa7;
}
.blue-box {
  background: #4dcad3;
}
hr:not([size]) {
  height: 3px;
}
hr {
  background-color: #f3f6fa;
}
/*  */
/* .tab-container {
  width: 80%;
  margin: auto;
  margin-top: 50px;
} */

.file-name {
  width: 50px; /* Match this width to the icon's width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
}

.tab-link {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 2px solid transparent;
  color: #5578ba;
  font-size: 18px;
}
.tab-link.active {
  background-color: transparent !important;
  border-bottom: 2px solid #082064;
}
.tab-link:hover {
  background-color: #ddd;
}

.tab-link.active {
  background: #082064;
}

.tab-content {
  display: none;
}
.add-services-style {
  width: 80%;
  margin: 0 auto;
}
.add-parameters {
  background: #5bced7;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  line-height: 28px;
}
.add-services-para {
  padding: 20px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  margin-top: 15px;
}
.customer-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.first-section {
  /* display: flex; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}
.box-one {
  width: 20%;
}
.box-two {
  width: 50%;
}
.box-three {
  width: 30%;
}
.sectiontwo {
  display: flex;
  gap: 20px;
}
.sectiontwo-one {
  width: 20%;
}
.sectiontwo-two {
  width: 24%;
}
.sectiontwo-three {
  width: 24%;
}
.sectiontwo-four {
  width: 28%;
}
.box-two .progress {
  background: #f3f6fa;
}
.box-two .progress .progress-bar {
  background: #14cee9;
}
.box-two .progress-sec .progress .progress-bar {
  background: #1c66b4;
}
.device-live-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.device-live-status li {
  border: 1px solid;
  padding: 8px;
  border-radius: 20px;
  min-width: 80px;
  font-size: 13px;
  text-align: center;
}
.blue-color {
  border-color: #1c66b4;
  color: #1c66b4;
}
.bluelight-color {
  border-color: #14cee9;
  color: #14cee9;
}
.greenlight-color {
  border-color: #3cecc8;
  color: #3cecc8;
}
.gray-light-color {
  border-color: #92c9be;
  color: #92c9be;
}
.multi-colorbar .bg-primary {
  background-color: #1c66b4 !important;
}
.multi-colorbar .bg-success {
  background-color: #14cee9 !important;
}
.multi-colorbar .bg-warning {
  background-color: #24d4b0 !important;
}
.multi-colorbar .bg-danger {
  background-color: #92c9be !important;
}
.lightgreen-bar {
  background-color: #3cecc8 !important;
}
/* graph */
.speedometer {
  display: inline-block;
  /* width: 200px;
  height: 200px; */
  width: 175px;
  height: 175px;
  position: relative;
}
.speedometer .gauge {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* background: conic-gradient(
      #0d6efd 0deg, 
      #0d6efd 90deg, 
      #6c757d 90deg,
      #6c757d 270deg,
      #dc3545 270deg,
      #dc3545 360deg
  ); */
  background: url(./Assets/Images/wanstatus.png) no-repeat;
  background-size: contain;
  position: relative;
}
.speedometer .gauge .needle {
  width: 3px;
  height: 41%;
  /* background: #000; */
  position: absolute;
  top: 4%;
  left: 49%;
  transform-origin: bottom center;
  transform: rotate(0deg);
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 83px solid #3ec8e5;
}
.speedometer .gauge .center {
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #3ec8e5 0%, #f2a735 100%);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.speedometer .label {
  text-align: center;
  margin-top: 20px;
}
.speedometer .value {
  font-size: 20px;
  font-weight: bold;
  color: #082064;
}
/*  */
.small-graph-box h6 {
  color: #9fb0d0;
}
/* graph */
.container {
  /* position: relative;
  width: 80%;
  margin: 50px auto;
  border: 4px solid transparent;
  border-radius: 15px;
  overflow: hidden;
  padding: 20px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}
.container::before {
  /* content: '';
  position: absolute;
  top: -4px; left: -4px; right: -4px; bottom: -4px;
  border-radius: 15px; */
  /* background: linear-gradient(90deg, #3EC8E5 0%, #F2A735 100%); */
  /* z-index: -1; */
}
/* .content {
  position: relative;
  z-index: 1;
  background: white;
  padding: 20px;
  border-radius: 15px;
} */
/* .info {
  font-size: 24px;
  margin-bottom: 20px;
}
.info .percentage {
  font-size: 48px;
  font-weight: bold;
} */
.chart-container {
  position: relative;
  height: 400px;
  width: 100%;
}
.chart-container canvas {
  width: 100% !important;
}
/* end graph */
.margin-top-dashboard {
  margin-top: 55px;
}
.margin-top-network {
  margin-top: 95px;
}
.nav-mobile .sub-list li a {
  color: #fff;
}

/*  */
/* .wrapper
{
  display: flex;
  align-items: baseline;
  height: 2em;
}
.fr1 {
    margin: 0;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
}

.fr1 a {
  padding: 5px 10px 5px 10px;
    background-color: transparent;
    color: #082064;
    line-height: 20px;
    text-decoration: none;
    transition: all 500ms ease;
    border: 1px solid #8893B7;
    font-size: 15px;
    border-radius: 4px;
}

.fr1 a:hover {
  background-color: transparent;
  color: #082064;
  text-decoration: none;
}

.pos {
  position: absolute;
  left: -20px;
  top: 0px;
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRP_KP2HHUb1ZKYROZxakpF13CzPOqJF23JytQNo05OJm7hsVIzg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.pos2 {
  position: absolute;
  left: -20px;
  top: 0px;
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ9n-qfKp3bLa5_zdX6FyWfjKM-1aI_U2qxtQdnMsEW4lAgIlp");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.toggledAnother {
  height: 25px;
}

.toggledAnother {
  color: #333;
  background-color: transparent;
  display: none;
}


ul.horizontalMenu3 {
  list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.horizontalMenu3 li {
  display: inline-block;
  margin: 0 10px 0px 0;
}

ul.horizontalMenu3 li:last-child {
  margin-right: 30px;
}

ul.horizontalMenu3 li a {
  color: #333;
  text-decoration: none;
  font-size: 14px;
}
ul.horizontalMenu3 li a.btn {
  border-radius: 10px;
    padding: 1px 10px;
    font-size: 14px;
    border: 1px solid;
    font-weight: 400;
    border: 1px solid #d2d2d2;
}
ul.horizontalMenu3 li a:hover {
  color: #fff;
} */
.choices__inner select {
  width: 100%;
  padding-right: 2.5em; /* Space for the dropdown icon */
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none; /* Remove default background */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5em 1em;
  font-size: 1em;
  color: #333;
}

.choices__inner::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 15px;
  color: #082064;
}
.MuiPaper-root {
  padding: 25px 25px;
}
.btn-space img {
  width: auto;
  margin-right: 10px;
}

@media (max-width: 1400px) {
  .inside-right-box {
    width: 75%;
  }
  .inside-box h1 {
    font-size: 58px;
  }
  .register-page {
    padding: 45px 0;
  }
}
/* responsive */
@media (max-width: 1910px) {
  .register-page .form-details .mb-4 {
    margin-bottom: 0.5rem !important;
  }
  .register-page .form-details .form-control {
    padding: 10px 0 10px 10px;
    font-size: 15px;
  }
  .register-page .icon-position {
    bottom: 11px;
  }
  .register-page .form-select {
    padding: 10px 0 10px 10px;
  }
  .icon-eye {
    position: absolute;
    right: 15px;
  }
}
@media (max-width: 1300px) {
  .first-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
  }
  .table-style-dashboard {
    width: 100%;
  }
}
@media (min-width: 1213px) and (max-width: 1542px) {
  .inside-box h1 {
    font-size: 60px;
  }
}
@media (min-width: 1030px) and (max-width: 1307px) {
  .login-left-inside {
    width: 66%;
    margin: 0 auto;
    padding: 40px 0 30px;
  }
  .inside-right-box {
    width: 65%;
  }
}

.theme-toggle {
  background: #ededf0; /* Default for light mode */
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.theme-toggle.dark {
  background: #161c29; /* Dark mode background */
}

.theme-toggle img {
  width: 20px;
}

[data-theme="dark"] {
  background-color: #121212;
  color: #ffffff;
}

[data-theme="light"] {
  background-color: #ffffff;
  color: #000000;
}

.otp-resend-active {
  margin-left: 10px;
  cursor: pointer;
  color: #0d6efd;
  border-bottom: 1px solid #0d6efd;
}

.otp-resend-active:hover {
  color: #009dcf;
  border-bottom: 1px solid #009dcf;
}

.otp-resend-disable {
  margin-left: 10px;
  cursor: not-allowed;
  color: #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.notification-delete {
  border: 1px solid #8893b7;
  padding: 5px 10px;
  border-radius: 5px;
}

/*  */

.progress-bar__wrapper {
  position: relative;
  width: 50%;
}
.progress-bar__value {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  color: #fff !important;
}
progress {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background-color: #e9ecef;
  transition: width 300ms ease;
}
progress[value]::-webkit-progress-bar {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background-color: #e9ecef;
  transition: width 300ms ease;
}
progress[value]::-webkit-progress-value {
  width: 0;
  border-radius: 50px;
  background-color: #36c3ce;
  transition: width 300ms ease;
}
.dropdown-item:hover {
  background: #f1f1f1 !important;
}
.notification-style {
  /* position: absolute;
  top: -14px;
  right:-14px;
  background: #E70404;
  border-radius: 15px;
  padding: 4px;
  cursor: pointer; */
  position: absolute;
  display: grid;
  height: 25px;
  width: 25px;
  top: -14px;
  right: -14px;
  background: #e70404;
  border-radius: 15px;
  padding: 3px;
  cursor: pointer;
  place-items: center;
  font-size: 12px;
}
.notification-style p {
  font-size: 11px;
  color: #fff;
}
.notification-box .dropdown-toggle::after {
  display: none !important;
}
.notification-box .dropdown-menu.show {
  width: 600px;
  padding: 0;
  border-radius: 20px;
  height: 630px;
  overflow-y: scroll;
}
.notification-box .dropdown-item {
  padding: 1rem !important;
  border-bottom: 1px dashed #d9d9d9;
}
.notification-box .dropdown-item:last-child {
  border-bottom: 0px dashed #d9d9d9;
}
.inside-notify {
  background: #e9e9e9;
  padding: 3px;
  font-size: 14px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  margin-left: 15px;
}
.hightlight-notify {
  color: #082064;
  font-weight: 500;
}
.hightlight-notify-read {
  color: #8c8c8c;
  font-weight: 400;
}
[data-theme="dark"] .notification-box .dropdown-menu.show {
  background: #303030;
}
[data-theme="dark"] .dropdown h5 {
  color: #fff !important;
}
[data-theme="dark"] .hightlight-notify-read {
  color: #a7aaaf;
}
[data-theme="dark"] .hightlight-notify {
  color: #ffffff;
}
[data-theme="dark"] .notification-box .dropdown-item:hover {
  background: #434343 !important;
}
[data-theme="dark"] .notification-box .dropdown-toggle img {
  filter: invert(1) !important;
}

/* width */
.dropdown-menu.show::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.dropdown-menu.show::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.dropdown-menu.show::-webkit-scrollbar-thumb {
  background: #4c4c4d;
  border-radius: 10px;
}

/* Handle on hover */
.dropdown-menu.show::-webkit-scrollbar-thumb:hover {
  background: #121213;
}
#appt {
  width: 175px;
}
.filter-style .bg-filter-style button {
  border: 1px solid #dfdee3;
  background: transparent;
  color: #5578ba !important;
  border-radius: 5px;
  padding: 11px 25px;
}
.filter-style .bg-filter-style button {
  color: white !important;
  background-color: #082064;
}
.edit-btn-dashboard {
  cursor: pointer;
  padding: 11px;
  background: #ffffff;
  border-radius: 50px;
  margin-bottom: 10px;
}
.edit-btn-dashboard img {
  width: 18px;
}
/* 
.notification-dot-wrapper {
  display: flex;
  gap: 15px;
}

.notification-dot {
  height: 25px;
  width: 25px;
  font-size: 12px;
  display: grid;
  place-items: center;
  border-radius: 15px;
  background-color: red;
} */
.reports-table .MuiPaper-root {
  padding: 0;
}
.reports-table .MuiBox-root {
  z-index: 0;
}
.filter-img.filter-style .searchWrapper {
  padding: 11px 10px;
  width: 260px;
}
.bg-filter-style {
  margin-right: 15px;
}
.modal-dropdownnew-style button {
  padding: 0.6rem 0.75rem;
}
[data-theme="dark"] .filter-style button {
  border: 1px solid #444444;
}
[data-theme="dark"] .border {
  border: var(--bs-border-width) var(--bs-border-style) #595959 !important;
}
[data-theme="dark"] .button-icon img {
  filter: invert(0%);
}
[data-theme="dark"] .dropdownnew-style button {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: transparent;
}
[data-theme="dark"] .fullscreen-btn svg {
  filter: invert(1);
}
[data-theme="dark"] .profile-edit .form-control {
  color: #ffffff !important;
}
[data-theme="dark"] .configure-body .secondary {
  background-color: #161618;
}
