.profile-heading {
  font-size: 18px;
  color: #082064;
  font-weight: 600;
  margin-bottom: 20px;
}

.profile-detail-wraper {
  display: grid;
  gap: 10px;
  font-size: 16px;
}

.profile-label {
  font-weight: 400;
  color: #5578ba;
  width: 100px;
}

.profile-value {
  color: #082064;
  font-weight: 400;
}

.button-alignment {
  padding: 40px;
}
.logo-style
{
  /* border: 1px solid #F6F6FC; */
  border-radius: 20px 20px 20px 20px;
  padding: 5px;
  position: relative;
}
.logo-style img
{
 width: 170px;
}
.company-name
{
  margin-left: 15px;
}
.company-name h4
{
  font-size: 25px;
  color: #082064;
font-weight: 600;
}
.company-name p 
{
  color: #08206466;
  font-size: 18px;
  font-weight: 400;
}
.profile-section 
{
  border: 1px solid #F3F3F3;
  border-radius: 20px;
  padding: 45px 35px;
  background: #FFFFFF;
}
.profile-section h3 
{
font-size: 20px;
font-weight: 400;
color: #082064;
margin-bottom: 35px;
}
.profile-space label 
{
  font-size: 16px;
font-weight: 400;
color: #08206480 !important;
margin-bottom: 12px;
}
.profile-space p 
{
  color: #082064 !important;
  font-size: 16px;
  font-weight: 400;
}
.profile-space
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}
.profile-left
{
  width: 50%;
    margin: 0 137px 0 0;
}
.profile-right
{
  width: 50%;
    margin: 0 137px 0 0;
}
.btn-success
{
  background: #36C3CE;
color: #fff;
border-color: #36C3CE;
min-width: 150px;
}

.btn-success:hover {
  background: #36C3CE;
color: #fff;
border-color: #36C3CE;
}
.btn-cancel
{
  background: transparent;
  color: #082064;
  border: 1px solid #E3E3E3;
  min-width: 150px;
  border-radius: 10px;
}
.upload-img-profile img 
{
  width: 25px;
    position: absolute;
    right: -10px;
    bottom: -8px;
    background: #5BCED7;
    padding: 6px;
    border-radius: 15px;
    cursor: pointer;
}
.profile-edit p 
{
  color: #08206466;
font-size: 16px;
font-weight: 400;
}
.public-profile
{
font-size: 16px !important;
font-weight: 400 !important;
color: #082064 !important;

}
.profile-edit .form-control
{
  color: #082064 !important;
}


[data-theme="dark"] .company-name h4
{
  color: #ffffff;
}
[data-theme="dark"] .company-name p {
  color: #ffffff66;
}

[data-theme="dark"] .profile-section {
  border: 1px solid #242424;
  background: #161618;
}

[data-theme="dark"] .profile-section h3 
{
  color: #ffffff;
}
[data-theme="dark"] .profile-space label
{
  color: #ffffff80 !important;
}

[data-theme="dark"] .profile-space p {
  color: #ffffff !important;
}

[data-theme="dark"] .btn-cancel 
{
  color: #ffffff;
}


[data-theme="dark"] .profile-edit p {
  color: #ffffff66;
}

[data-theme="dark"] .public-profile {
  color: #ffffff !important;
}